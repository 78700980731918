import "../../assets/css/FileUploadHistory.css"
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
const SiteBar = () => {
  return (
    <div className="fileuploadhistory-child">
      <div className="fileuploadhistory-item" />
      <div className="rectangle-parent">
        <div className="group-child" />
        <div className="dashboard-parent">
          <Link to="/fileuploadhistory" className="dashboard">
            History
          </Link>
          <img className="group-item" alt="" src="/Group_42154.svg" />
        </div>
      </div>
      <div className="bulk-upload-parent">
        <Link to="/bulkupload" className="bulk-upload">
          Bulk Upload
        </Link>
        <img className="vector-icon1" alt="" src="/Vector.svg" />
      </div>
      <div className="fileuploadhistory-inner" />
    </div>
  );
};

export default SiteBar;

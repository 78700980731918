import React from "react";
import "../../assets/css/FilteredCardFormContainer.css";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  return (
    <div className="rectangle-parent2">
      <div className="group-child37" />
      <div className="group-wrapper">
        <div className="to-50-of-52-parent">
          <div className="to-50-of-container">
            <span className="span">{`${(currentPage - 1) * 10 + 1}  `}</span>
            <span className="to">to</span>
            <span className="span">{`  ${currentPage * 10}  `}</span>
            <span className="to">of</span>
            <span className="span"> {totalPages * 10}</span>
          </div>
          <div className="page-1-of-2-parent">
            <div className="page-1-of-container">
              <span>Page</span>
              <span className="span3">{` ${currentPage}  `}</span>
              <span>of</span>
              <span className="span3"> {totalPages}</span>
            </div>
            <div className="arrowbutton" onClick={() => handlePageChange(currentPage - 1)}>
              <img className="vector-icon3" alt="" src="/VectorB.png" />
            </div>
            <div className="vector-group">
            <div className="arrowbutton" onClick={() => handlePageChange(1)}>
                <img className="i" alt="" src="/I.png" />
                <img className="vector-icon4" alt="" src="/VectorB.png" />
            </div>
            </div>
            <div className="arrowbutton" onClick={() => handlePageChange(totalPages)}>
            <img className="group-child38" alt="" src="/group-41982@2x.png" />
            </div>
            <div className="arrowbutton" onClick={() => handlePageChange(currentPage + 1)}>
            <img className="vector-icon5" alt="" src="/VectorF.png" />
            </div>
          </div>
          <img className="line-icon" alt="" src="/line-238@2x.png" />
        </div>
      </div>
    </div>
  );
};

export default Pagination;

